@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #302f2f;
  border-radius: 10px;
  box-shadow: 2px 2px 20px black;
}

::-webkit-scrollbar-track {
  background-color: #1b1b1b;
}

.react-select__menu {
  background-color: lightblue;
  /* Change this to your desired background color */
}