*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.disable-scroll {
  overflow: hidden;
}

button {
  outline: none;
}

/* Remove the blue overlay on active state for the button */
*:active {
  -webkit-tap-highlight-color: transparent;
  /* For WebKit-based browsers */
  tap-highlight-color: transparent;
  /* For modern browsers */
}

.leightBlack-bg {
  background-color: rgb(15, 12, 15);
}

.custom-line-height {
  line-height: 1.5;
}

.highlter-bg {
  background-color: rgb(24, 20, 20);
}

.darkgray-shadow {
  --tw-shadow-color: rgb(50, 50, 50);
  --tw-shadow: var(--tw-shadow-colored);
}


body {
  scroll-behavior: smooth;
  color: whitesmoke;
  background-color: black;
  position: relative;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
}

/* Customize scrollbar for all browsers */
.custom-scrollbar::-webkit-scrollbar {

  scroll-behavior: smooth;
  width: 4px;
  /* Width of the vertical scrollbar */
  height: 4px;
  /* Height of the horizontal scrollbar */
}

/* Customize the scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
  margin: 8px;
  background: #000;
  /* Background color of the track */
}

/* Customize the scrollbar thumb (the draggable part) */
.custom-scrollbar::-webkit-scrollbar-thumb {

  background: #7d7373;
  /* Background color of the thumb */
  /* Rounded corners for the thumb */
}

/* Customize the scrollbar thumb on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {


  background: #c2baba;

  /* Background color of the thumb on hover */
}

.custom-scrollbar {

  scroll-behavior: smooth;
  scrollbar-width: thin;
  /* Width of the scrollbar (both horizontal and vertical) */
  scrollbar-color: #888 #000;
  /* Color of the thumb and track (both horizontal and vertical) */
  /* Customize scrollbars for IE and Edge */
  -ms-overflow-style: none;
  /* Hide the scrollbar in IE and Edge */

}

/* Define a class for the element where you want to hide the scrollbar */
.scrollable-content::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 0px;
  /* Width of the vertical scrollbar */
  height: 0px;
  /* Height of the horizontal scrollbar */
}

/* Customize the scrollbar track */
.scrollable-content::-webkit-scrollbar-track {
  scroll-behavior: smooth;
  margin: 0px;
  background: #000;
  /* Background color of the track */
}