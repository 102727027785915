.background-radial-gradient {
  background-color: black;
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -150px;
  left: -120px;
  background: radial-gradient(#000, #404040);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -20px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#000, #404040);
  overflow: hidden;
}
