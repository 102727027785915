.button-click-animation {
   animation: buttonClick 0.4s;
}

@keyframes buttonClick {
   0% {
      transform: scale(1);
      background-color: #f56565;
   }

   50% {
      transform: scale(0.6);
      background-color: #930e0e;
   }

   100% {
      transform: scale(1);
      background-color: #f56565;
   }
}