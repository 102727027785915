.news-container {
   margin-inline: auto;
   position: relative;
   font-family: "Roboto", sans-serif;
   /* min-width: 100%; */
   box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

.news-container .title {
   position: absolute;
   background: #6d1919;
   height: 100%;
   display: flex;
   padding: 0px 5px;
   align-items: center;
   color: white;
   font-size: small;
   font-weight: bold;
   z-index: 4;
}

.news-container ul li {
   white-space: nowrap;
   color: rgb(222, 218, 218);
   position: relative;
}

.news-container ul li::after {
   content: "";
   width: 1px;
   height: 100%;
   background: #7f7f7f;
   position: absolute;
   top: 0;
   right: 0;
}

.news-container ul li:last-child::after {
   display: none;
}

.news-container ul:hover {

   animation-play-state: paused;

}

.news-container ul {
   color: #494949;
   list-style: none;
}

@keyframes scroll {
   0% {
      transform: translateX(300px);
   }

   100% {
      transform: translateX(-100%);
   }
}

@media screen and (min-width: 550px) {

   @keyframes scroll {
      0% {
         transform: translateX(600px);
      }

      100% {
         transform: translateX(-100%);
      }
   }
}

@media screen and (min-width: 800px) {

   @keyframes scroll {
      0% {
         transform: translateX(850px);
      }

      100% {
         transform: translateX(-100%);
      }
   }
}