.image-container {
  margin-top: -10em;
}

@media screen and (max-width: 1024px) {
  .image-container {
    margin: 2em 0em;
  }
}

@media screen and (max-width: 768) {
  .image-container {
    margin: 1em 0em;
  }
}
